import React from "react";
import "./Programs/programs.css";

export const Programs = (props) => {
  return (
    <div id="programs" className="text-center" style={{width:"100%"}}>
      <div className="container">
        <div className="section-title">
          <h2>Our Programs</h2>
          <p style={{color:"#333333"}}>
            Explore our diverse programs designed to elevate every aspect of a young athlete's development.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="program-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
