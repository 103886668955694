import React, { useState, useEffect, useRef,useCallback } from "react";
import "../modals/registrationModal.css";
import { RegistrationModal } from "../modals/RegisterModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

export const NavigationMain = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  // State for managing URLs
  const [hrefPerformance, setHrefPerformance] = useState('/');
  const [hrefAba, setHrefAba] = useState('/aba');
  const [hrefIndividual, setHrefIndividual] = useState('/individual');
  const hrefRegister = '/register';
  
  const menuRef = useRef();
  const menuButtonRef = useRef();

  useEffect(() => {
    const currentPath = window.location.pathname;

    // Default href values
    setHrefPerformance('#page-top');
    setHrefAba('/aba');
    setHrefIndividual('/individual');

    // Update href based on the current path
    if (currentPath.includes('/aba')) {
      setHrefPerformance('/');
      setHrefAba('#page-top');
      setHrefIndividual('/individual');
    } else if (currentPath.includes('/individual')) {
      setHrefPerformance('/');
      setHrefAba('/aba');
      setHrefIndividual('#page-top'); // This can scroll to the top when already on individual page
    } else if (currentPath.includes('/register')) {
      setHrefPerformance('/');
      setHrefAba('/aba');
      setHrefIndividual('/individual');
    }
  }, []);

  // Function to handle smooth scrolling
  useEffect(() => {
    const handleScroll = (event) => {
      event.preventDefault(); // Prevent default anchor link behavior

      const targetId = event.currentTarget.getAttribute("href").substring(1);
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        let offset = -150; // Default offset
        // Determine offset based on window width
        if (window.innerWidth < 287) {
          offset = -220;
        } else if (window.innerWidth < 450) {
          offset = -150;
          if (targetElement.id === "gallery-aba") {
            offset += 10;
          }
        } else if (window.innerWidth < 768) {
          offset = -170;
          if (targetElement.id === "gallery-aba") {
            offset -= 20;
          }
        } else if (window.innerWidth < 1116) {
          offset = -180;
        if (targetElement.id === "gallery-aba") {
            offset -= 10;
          }
        }
        if (targetElement.id === "gallery-aba") {
          offset -= 20;
        }

        const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition + offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });

        setIsMenuOpen(false); // Close the menu after scrolling
      }
    };

    const links = document.querySelectorAll(".page-scroll");
    links.forEach((link) => {
      link.addEventListener("click", handleScroll);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", handleScroll);
      });
    };
  }, []);

  // Function to handle menu toggle
  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
  };

  // Function to close menu when clicking outside of it
  const handleClickOutsideMenu = useCallback((event) =>  {
    if (menuRef.current && !menuRef.current.contains(event.target) && isMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [isMenuOpen]);

  // Effect to attach click outside handler when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutsideMenu);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, [isMenuOpen,handleClickOutsideMenu]);

  return (
    <div>
      <nav id="menu" className="navbar navbar-expand-lg bg-dark navbar-dark fixed-top" ref={menuRef}>
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleMenuToggle}
            aria-controls="navbarNav"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            ref={menuButtonRef}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          
          <div className="d-flex align-items-center justify-content-center flex-row flex-wrap flex-md-nowrap custom-lg-w-100">
            <div className="d-flex align-items-center">
              <a className={`navbar-brand responsive-img ${!(window.location.pathname.includes("register") || window.location.pathname.includes("aba") || window.location.pathname.includes("individual")) ? 'page-scroll' : ''}`} 
                href={hrefPerformance}>
                <img src="img/logoWhite.png" alt="Logo" 
                    style={{ 
                        maxWidth: window.innerWidth <= 450 ? '150px' : '200px', 
                        maxHeight: "140px",  
                    }} className="" />
              </a>
            </div>
            <div className="d-flex flex-column align-items-center">
              <a className={`navbar-brand ${window.location.pathname.includes("/aba") ? 'page-scroll' : ''}`} 
                href={hrefAba}>
                <img src="img/aba_LogoWhiteN.png" alt="Logo" 
                    style={{ 
                        maxWidth: window.innerWidth <= 450 ? '80px' : '140px', 
                        maxHeight: "140px",  
                    }} />
              </a>
            </div>
          </div>

          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
            <ul className={`navbar-nav ms-auto align-items-center justify-content-center d-flex custom-flex-row-326`}>
              <li className="nav-item d-flex align-items-center justify-content-center navigation-list-item-text" style={{ flex: '1' }}> 
                <a className={`btn text-white ${!(window.location.pathname.includes("register") || window.location.pathname.includes("aba") || window.location.pathname.includes("individual")) ? 'page-scroll' : ''} navigation-ulist-font text-center`} 
                  href={hrefPerformance}>
                  Dr. Performance <br /> Skills &  Mindset <br/> Camps
                </a>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center navigation-list-item-text" style={{ flex: '1' }}>
                <a className={`btn text-white ${window.location.pathname.includes("/aba") ? 'page-scroll' : ''} navigation-ulist-font text-center`} 
                  href={hrefAba}>
                  Amsterdam <br /> Basketball Academy
                </a>
              </li>
              <li className="nav-item d-flex align-items-center justify-content-center navigation-list-item-text" style={{ flex: '1' }}>
                <a className={`btn text-white ${window.location.pathname.includes("/individual") ? 'page-scroll' : ''} navigation-ulist-font text-center`} 
                  href={hrefIndividual}>
                  Individual <br /> Practices
                </a>
              </li>
            </ul>
          </div>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : 'collapse'}`} id="navbarNav">
            <ul className={`navbar-nav ms-auto align-items-center justify-content-center navbar-nav flex-wrap`}>
              <li className="nav-item d-flex">
                <a href="https://www.instagram.com/drperformanceacademy/" target="_blank" rel="noopener noreferrer" className="btn btn-dark">
                  <FontAwesomeIcon icon={faInstagram} size="lg" inverse/>
                </a>
              </li>
              <li className="nav-item">
                <a href="#page-top" className="nav-link page-scroll fs-4">
                  About Us
                </a>
              </li>
              {!window.location.pathname.includes("/individual") && (
                <li className="nav-item">
                  <a href="#programs" className="nav-link page-scroll fs-4" >
                    Programs
                  </a>
                </li>
              )}
              <li className="nav-item">
                <a href="#gallery-aba" className="nav-link page-scroll fs-4">
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a href="#staff" className="nav-link page-scroll fs-4">
                  Staff
                </a>
              </li>
              <li className="nav-item">
                <a href="#contact" className="nav-link page-scroll fs-4" >
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a className="btn btn-danger fs-4 d-flex justify-content-center align-items-center m-2 p-2" href={hrefRegister}>
                  <p className="custom-font-sans mb-0">
                    REGISTER
                  </p>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Registration Modal */}
        {isModalOpen && <RegistrationModal onClose={() => setIsModalOpen(false)} />}
      </nav>
    </div>
  );
};
