import React from "react";
import "./About/about.css"

export const About = (props) => {
  return (
    <div id="about" style={{marginTop: "50px",width:"100%"}}>
      <div className="container">
        <div className="row" style={{backgroundColor:"#f6f6f6",paddingTop: "10px"}}>
          <div className="col-xs-12 col-md-6" style={{backgroundColor:"#f6f6f6",alignItems: "center", display: "flex", justifyContent: "center"}}>
            <img src="img/suit.jpg" className="img-responsive" alt="Dr Performance" style={{objectFit: "cover", width: "60%", height: "auto",cursor:"default"}} />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              {props.data ? props.data.paragraph.map((d, i) => (
                <p key={`${d}-${i}`}>{d}</p>
              )) : "loading..."}
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data ? props.data.Why.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    )) : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data ? props.data.Why2.map((d, i) => (
                      <li key={`${d}-${i}`}> {d}</li>
                    )) : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};