import React, { useState, useRef, useEffect } from "react";
import "./Staff/staff.css";

export const Staff = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalName, setModalName] = useState('');
  const modalRef = useRef(null);

  const openModal = (bio, name) => {
    setModalContent(bio);
    setModalName(name);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Add event listener when modal is shown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal]);

  return (
    <div id="staff" className="text-center" style={{backgroundColor:"#f6f6f6", width:"100%"}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 section-title">
            <h2>Staff</h2>
          </div>
        </div>
        
        <div className="row justify-content-center">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-lg-3 col-md-4 col-sm-6 mb-4">
                  <div className="" onClick={() => openModal(d.bio, d.name)} >
                    <div className="staff-img-container" style={{ width: '200px', height: '220px' }}>
                      <img src={d.img} alt={d.name} className="card-img-top staff-img" style={{ width: '100%', height: '100%' }} />
                      <button className="info-button " onClick={(e) => {
                        e.stopPropagation();
                        openModal(d.bio, d.name);
                      }}>i</button>
                    </div>
                    <div className="card-body">
                      <h4 className="card-title" style={{color:"black"}}>{d.name}</h4>
                      <p className="card-text" style={{color:"black"}}>{d.job}</p>
                      <p className="card-text">{d.title}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>

      {showModal ? (
        <div
          className="custom-modal-overlay"
          tabIndex="-1"
          role="dialog"
          style={{ display: 'flex' }}
        >
          <div className="custom-modal" role="document" ref={modalRef}>
            <div className="custom-modal-content">
              <div className="custom-modal-header">
                <button
                  type="button"
                  className="custom-close-button"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  &times;
                </button>
                <h4 className="custom-modal-title" style={{ textAlign: 'center' }}>
                  About {modalName}
                </h4>
              </div>
              <div className="custom-modal-body">
                <p>{modalContent}</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

    </div>
  );
};
