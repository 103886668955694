import React, { useState } from "react";

export const Image = ({ title, largeImage, smallImage }) => {


  return (
    <>
      <img
        src={smallImage}
        alt={title}
        style={{ cursor: "pointer", width: "100%" }}
      />
    </>
  );
};
