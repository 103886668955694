import React from "react";
import { NavigationRegister } from "./navigationRegister";
import "./Register/registerpage.css";

export const Registerpage = (props) => {
  return (
    <div>
      <div>
        <NavigationRegister></NavigationRegister>
      </div>
      <div id="registerScreen" className="text-center">
        <div className="container-fluid d-flex justify-content-between align-items-center p-0" style={{ height: '100vh', width: '100%', paddingTop: "70px" }}>
          <div className="d-flex justify-content-center align-items-center" style={{
            backgroundImage: 'url(img/logoWhite.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '50%',
            height: '60%',
            marginTop: '10%'
          }}>
            <a 
              href="https://forms.gle/xdM8rLN6EqeF3L4W9" 
              className="btn btn-dark responsive-btn register-btn"
              style={{ paddingLeft: "15%", paddingRight: "15%", paddingTop: "3%", paddingBottom: "3%", fontWeight: "bold" }} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              REGISTER NOW
            </a>
          </div>
          <div className="d-flex justify-content-center align-items-center" style={{
            backgroundImage: 'url(img/aba_LogoWhite.png)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: '50%',
            height: '60%',
            marginTop: '10%'
          }}>
            <a 
              className="btn btn-dark responsive-btn register-btn"
              style={{ paddingLeft: "17%", paddingRight: "17%", paddingTop: "3%", paddingBottom: "3%", fontWeight: "bold" }} 
              target="_blank" 
              rel="noopener noreferrer" 
              href="https://forms.gle/JiwRqJKUQLdavo5q8"
            >
              REGISTER NOW
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
