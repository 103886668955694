import React from "react";
import { Drperformance } from "./components/drperformance";
import { Aba } from "./components/aba";
import { Registerpage } from "./components/registerpage";
import {Individual} from "./components/individual";
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

  return (
    <Router> 
      <Routes>
        <Route path = "/" Component={Drperformance}></Route>
        <Route path = "/aba" Component={Aba}></Route>
        <Route path = "/register" Component={Registerpage}></Route>
        <Route path = "/individual" Component={Individual}></Route>
        
      </Routes>
    </Router>
  );
};

export default App;
