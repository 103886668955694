import React from "react";

export const AbaAbout = (props) => {
  return (
    <div id="about" style={{width:"100%"}}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6"style={{}}>
            <img src="img/aba_Logo_Cut.png" className="img-responsive" alt="Amsterdam Basketball Academy" style={{ width: '100%', maxWidth: '800px', height: 'auto',maxHeight:"600px",backgroundColor:"#f2f2f2",cursor:"default" }}/>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Mission and Vision</h2>
              {props.data ? props.data.paragraph.map((d, i) => (
                <p key={`${d}-${i}`}>{d}</p>
              )) : "loading..."}
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data ? props.data.Why.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    )) : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data ? props.data.Why2.map((d, i) => (
                      <li key={`${d}-${i}`}> {d}</li>
                    )) : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};