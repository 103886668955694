import React, { useState, useEffect } from "react";
import { Staff } from "./Staff";
import { Contact } from "./contact";
import { NavigationMain } from "./navigationmain";
import JsonData from "../data/english.json";
import { GalleryIndividual } from "./galleryIndividual";
import "../App.css"
import { IpAbout } from "./ipAbout";



export const Individual= () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  const handleMargin = () => {
    if(window.innerWidth <= 768){
      return {marginTop: '100px'}
    }
    else return {marginTop: '150px'}
  }

  return (
    <div style={{backgroundColor:"#f6f6f6" }}>
      <div style={handleMargin()}>{/*First element margin*/}
        <NavigationMain></NavigationMain>
      </div>
      {/*<Header data={landingPageData.Header} />*/}
      <IpAbout data={landingPageData.AboutIndividual}/>
      <GalleryIndividual data={landingPageData.GalleryIP} />
      <Staff data={landingPageData.StaffIndividual} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};