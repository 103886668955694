// src > modals > RegisterModal.js
import React, { useState, useEffect } from "react";
import "./registrationModal.css";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import PoliciesModal from './PoliciesModal';
import { red } from '@mui/material/colors';

export const RegistrationModal = ({ onClose }) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [error, setError] = useState(false);

  const [formData, setFormData] = useState({
    parentsName: '',
    email: '',
    phoneNumber: '',
    playersFullName: '',
    playersAge: '',
    conditionDescription: '',
    foodAllergies: '',
    membership: {
      BC_Apollo: false,
      MBCA: false,
      None: false,
      Other: false,
    },
    categories: {
      U19: false,
      U18: false,
      U16: false,
      U14: false,
      U12: false,
      U10: false,
      Starting: false,
      SemiProPro: false,
    },
    acceptableHours: {
      Slot1: false,
      Slot2: false,
    },
  });

  const isMembershipSelected = () => {
    return Object.values(formData.membership).some(value => value);
  };

  const isCategorySelected = () => {
    return Object.values(formData.categories).some(value => value);
  };

  const isHourSelected = () => {
    return Object.values(formData.acceptableHours).some(value => value);
  };

  const handleSubmit = () => {
    if (!termsAccepted || !privacyAccepted) {
      setError(true);
      alert("You must accept the Terms and Conditions and the Privacy Policy to proceed.");
      return;
    } else if (!formData.parentsName || !formData.email || !formData.phoneNumber || !formData.playersFullName || !formData.playersAge || !isCategorySelected() || !isHourSelected()) {
      setError(true);
      alert("Please fill in all the required fields.");
      return;
    }
    setOpenConfirmation(true);
  };

  const handleCloseConfirmation = (agree) => {
    setOpenConfirmation(false);
    if (agree) {
      setShowToast(true);
      onClose();
    }
  };

  useEffect(() => {
    let timer;
    if (showToast) {
      timer = setTimeout(() => setShowToast(false), 5000);
    }
    return () => clearTimeout(timer);
  }, [showToast]);

  const handleChange = (event, category) => {
    setError(false);  // Reset errors when user starts to correct
    const { name, value, checked, type } = event.target;

    if (category) {
      setFormData({
        ...formData,
        [category]: {
          ...formData[category],
          [name]: type === 'checkbox' ? checked : value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const getTextFieldStyle = () => (error ? { style: { borderColor: red[500], '&:hover': { borderColor: red[700] }, '&.Mui-focused': { borderColor: red[900] } } } : {});

  return (
    <div className="modal-background" onClick={onClose}>
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>×</button>
        <div className="modal-title">Dr. Performance Post Season Practice Program Application</div>
        <form className="registration-form">
          <div className="question">Parent's Name</div>
          <TextField
            error={error && !formData.parentsName}
            helperText={error && !formData.parentsName ? "This field is required." : ""}
            label="Parent's Name"
            variant="outlined"
            value={formData.parentsName}
            onChange={(e) => handleChange(e)}
            name="parentsName"
            fullWidth
            margin="normal"
            InputProps={getTextFieldStyle()}
          />
          <div className="question">Email</div>
          <TextField
            error={error && !formData.email}
            helperText={error && !formData.email ? "This field is required." : ""}
            label="E-mail"
            variant="outlined"
            value={formData.email}
            onChange={(e) => handleChange(e)}
            name="email"
            fullWidth
            margin="normal"
            InputProps={getTextFieldStyle()}
          />
          <div className="question">Phone Number</div>
          <TextField
            error={error && !formData.phoneNumber}
            helperText={error && !formData.phoneNumber ? "This field is required." : ""}
            label="Phone Number"
            variant="outlined"
            value={formData.phoneNumber}
            onChange={(e) => handleChange(e)}
            name="phoneNumber"
            fullWidth
            margin="normal"
            InputProps={getTextFieldStyle()}
          />
          <div className="question">Player's Full Name</div>
          <TextField
            error={error && !formData.playersFullName}
            helperText={error && !formData.playersFullName ? "This field is required." : ""}
            label="Player's Full Name"
            variant="outlined"
            value={formData.playersFullName}
            onChange={(e) => handleChange(e)}
            name="playersFullName"
            fullWidth
            margin="normal"
            InputProps={getTextFieldStyle()}
          />
          <div className="question">Player's Age</div>
          <TextField
            error={error && !formData.playersAge}
            helperText={error && !formData.playersAge ? "This field is required." : ""}
            label="Player's Age"
            variant="outlined"
            value={formData.playersAge}
            onChange={(e) => handleChange(e)}
            name="playersAge"
            fullWidth
            margin="normal"
            InputProps={getTextFieldStyle()}
          />
          <div className="question">Does the player have any medical condition?</div>
          <TextField
            label="If yes, can you describe it?"
            variant="outlined"
            value={formData.conditionDescription}
            onChange={(e) => handleChange(e)}
            name="conditionDescription"
            fullWidth
            margin="normal"
            multiline
            rows={2}
          />
          <div className="question">Does the player have any food allergies?</div>
          <TextField
            label="If so, what are they?"
            variant="outlined"
            value={formData.foodAllergies}
            onChange={(e) => handleChange(e)}
            name="foodAllergies"
            fullWidth
            margin="normal"
            multiline
            rows={2}
          />

          {/* Membership Checkboxes */}
          <FormGroup>
            <div className={error && !isMembershipSelected() ? "question error" : "question"}>Current Membership:{error && !isMembershipSelected() && <span className="error-message"> - This field is required.</span>}</div>
            {Object.keys(formData.membership).map((membership) => (
              <FormControlLabel
                className="label-font-and-size"
                key={membership}
                control={
                  <Checkbox
                    checked={formData.membership[membership]}
                    onChange={(e) => handleChange(e, 'membership')}
                    name={membership}
                    sx={{
                      '&.Mui-checked': {
                        color: red[900],
                      },
                      '& .MuiSvgIcon-root': { fontSize: 20 },
                    }}
                  />
                }
                label={membership.replace(/_/g, ' ')}
              />
            ))}
          </FormGroup>

          <FormGroup>
            <div className={error && !isCategorySelected() ? "question error" : "question"}>Which category did he/she play during the 2023/24 season? {error && !isCategorySelected() && <span className="error-message"> - This field is required.</span>}</div>
            {Object.keys(formData.categories).map((category) => (
              <FormControlLabel
                className="label-font-and-size"
                key={category}
                control={
                  <Checkbox
                    checked={formData.categories[category]}
                    onChange={(e) => handleChange(e, 'categories')}
                    name={category}
                    sx={{
                      '&.Mui-checked': {
                        color: red[900],
                      },
                      '& .MuiSvgIcon-root': { fontSize: 20 },
                    }}
                  />
                }
                label={category.replace(/([A-Z])/g, ' $1').trim()}
              />
            ))}
          </FormGroup>

          <FormGroup>
            <div className="question">Does the player have a chronic injury?</div>
            <FormControlLabel
              className="label-font-and-size"
              control={<Checkbox checked={formData.chronicalInjury === 'Yes'} onChange={() => setFormData({ ...formData, chronicalInjury: 'Yes' })} sx={{
                '&.Mui-checked': {
                  color: red[900],
                },
                '& .MuiSvgIcon-root': { fontSize: 20 },
              }} />}
              label="Yes"
              name="chronicalInjury"
            />
            <FormControlLabel
              className="label-font-and-size"
              control={<Checkbox checked={formData.chronicalInjury === 'No'} onChange={() => setFormData({ ...formData, chronicalInjury: 'No' })} sx={{
                '&.Mui-checked': {
                  color: red[900],
                },
                '& .MuiSvgIcon-root': { fontSize: 20 },
              }} />}
              label="No"
              name="chronicalInjury"
            />
          </FormGroup>

          <FormGroup>
            <div className={error && !isHourSelected() ? "question error" : "question"}>Which hours are acceptable for you? {error && !isHourSelected() && <span className="error-message"> - This field is required.</span>}</div>
            <FormControlLabel
              className="label-font-and-size"
              control={<Checkbox checked={formData.acceptableHours.Slot1} onChange={(e) => handleChange(e, 'acceptableHours')} value="Slot1" name="Slot1" sx={{
                '&.Mui-checked': {
                  color: red[900],
                },
                '& .MuiSvgIcon-root': { fontSize: 20 },
              }} />}
              label="Monday/Wednesday/Friday at 17:00 to 19:00"
            />
            <FormControlLabel
              className="label-font-and-size"
              control={<Checkbox checked={formData.acceptableHours.Slot2} onChange={(e) => handleChange(e, 'acceptableHours')} value="Slot2" name="Slot2" sx={{
                '&.Mui-checked': {
                  color: red[900],
                },
                '& .MuiSvgIcon-root': { fontSize: 20 },
              }} />}
              label="Monday/Wednesday/Friday at 19:00 to 21:00"
            />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              className="label-font-and-size"
              control={<Checkbox checked={termsAccepted} onChange={() => setTermsAccepted(!termsAccepted)} sx={{
                '&.Mui-checked': {
                  color: red[900],
                },
                '& .MuiSvgIcon-root': { fontSize: 20 },
              }} />}
              label={
                <span>
                  I agree to the
                  <button type="button" className="link-button" onClick={() => setIsTermsOpen(true)}>
                    Terms and Conditions
                  </button>
                </span>
              }
            />
            <PoliciesModal isOpen={isTermsOpen} onClose={() => setIsTermsOpen(false)} title="Terms and Conditions">
              <p>Participating in Dr. Performance Basketball Camp involves certain inherent risks, including the potential for sustaining injuries. Your decision to participate in the camp is entirely at your own risk. Dr. Performance Basketball Camp, its organizers, coaches, volunteers, partners, and sponsors cannot be held liable in any way for any injuries, damages, and/or theft in any form that may occur during the camp.</p>
              <p>Dr. Performance Basketball Camp reserves the right to use and publish photos taken during the camp for promotional and informational purposes without the right to compensation for participants or others involved. By participating in the camp, you acknowledge and agree to this use of images.</p>
              <p>Additionally, Dr. Performance Basketball Camp reserves the right to refuse registrations without providing reasons.</p>
              <p>Your safety and well-being are of utmost importance to us. Please adhere to all camp rules, guidelines, and instructions provided by the organizers to ensure a safe and enjoyable experience for everyone.</p>
              <p>If you have any questions or concerns about this disclaimer, please contact us at info@drperformance.nl</p>
              <p>Thank you for your understanding and cooperation.</p>
              <p>© 2024/2025, Dr. Performance Basketball Camp. All rights reserved.</p>
              <button type="button" className="apply-button" onClick={() => setIsTermsOpen(false)}>Close</button>
            </PoliciesModal>
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              className="label-font-and-size"
              control={<Checkbox checked={privacyAccepted} onChange={() => setPrivacyAccepted(!privacyAccepted)} sx={{
                '&.Mui-checked': {
                  color: red[900],
                },
                '& .MuiSvgIcon-root': { fontSize: 20 },
              }} />}
              label={
                <span>
                  I agree to the
                  <button type="button" className="link-button" onClick={() => setIsPrivacyOpen(true)}>
                    Privacy Policy
                  </button>
                </span>
              }
            />
            <PoliciesModal isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} title="Privacy Policy">
              <p>Dr. Performance Basketball Camp collects necessary personal data from camp participants to efficiently organize the camp, establish agreements, and provide news updates and (future) invitations. Rest assured, your personal data will not be shared with third parties.</p>
              <p>Participation in Dr. Performance Basketball Camp requires the provision of certain personal information. If a participant chooses not to provide the necessary personal information, unfortunately, participation in the camp will not be possible.</p>
              <p>In accordance with legal requirements, participant data will be securely retained for a period of 7 years. Participants have the right to inspect, rectify, or delete their personal data (where possible within the law). Requests can be made by sending an email to info@drperformance.nl</p>
              <p>By participating in Dr. Performance Basketball Camp, you also grant the organization permission to publish photos of you on social media and/or the organization's website for promotional and informational purposes.</p>
              <p>Your privacy is important to us. If you have any concerns or questions regarding the handling of your personal data, please feel free to contact us.</p>
              <p>© 2024/2025, Dr. Performance Basketball Camp. All rights reserved.</p>
              <button type="button" className="apply-button" onClick={() => setIsPrivacyOpen(false)}>Close</button>
            </PoliciesModal>
          </FormGroup>
          <button type="button" className="apply-button" onClick={handleSubmit}>
            Submit
          </button>
        </form>

        {showToast && <div className="toast">Registration submitted!</div>}

        <Dialog
          open={openConfirmation}
          onClose={() => handleCloseConfirmation(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Submission"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to submit the registration?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCloseConfirmation(false)}>No</Button>
            <Button onClick={() => handleCloseConfirmation(true)} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

