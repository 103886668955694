import React, { useState, useEffect } from "react";
import { About } from "./about";
import { ProgramsPerformance } from "./programsperformance";
import { Staff } from "./Staff";
import { Contact } from "./contact";
import { NavigationMain } from "./navigationmain";
import JsonData from "../data/english.json";
import { GalleryPerformance } from "./gallery";
import "../App.css";



export const Drperformance = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  const handleMargin = () => {
    let margin = {marginTop: "150px"};

    if(window.innerWidth <= 992) {
      margin = {marginTop: '180px'}
      }

    if(window.innerWidth <= 768){
      margin = {marginTop: '120px'}
    }
    if(window.innerWidth <= 450){
      margin = {marginTop: '90px'}
    }
    if(window.innerWidth <= 287){
      margin = {marginTop: '170px'}
    }

    return margin;
  }

  return (
    <div style={{backgroundColor:"#f6f6f6",width:"100%"}}>
      <div style={handleMargin()}>{/*First element margin*/}
        <NavigationMain></NavigationMain>
      </div>
      {/*<Header data={landingPageData.Header} />*/}
      <About data={landingPageData.About} style={{width:"100%"}}/>
      <ProgramsPerformance data={landingPageData.ProgramsPerformance} />
      <GalleryPerformance data={landingPageData.Gallery} />
      <Staff data={landingPageData.Staff} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};
