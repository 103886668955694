import React from "react";

export const IpAbout = (props) => {
  return (
    <div id="about" style={{width:"100%"}}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6"style={{}}>
            <img src="img/individual.jpg" className="img-responsive" alt="Individual Practices" style={{ width: '100%', maxWidth: '500px', height: 'auto',maxHeight:"500px",backgroundColor:"#f2f2f2" }}/>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Individual Practices</h2>
              {props.data ? props.data.paragraph.map((d, i) => (
                <p key={`${d}-${i}`}>{d}</p>
              )) : "loading..."}
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data ? props.data.Why.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    )) : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data ? props.data.Why2.map((d, i) => (
                      <li key={`${d}-${i}`}> {d}</li>
                    )) : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};