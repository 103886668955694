import React from "react";
import "./Programs/programs.css";

export const ProgramsPerformance = (props) => {
    return (
        <div id="programs" className="text-center" style={{width:"100%"}}>
            <div className="container">
                <div className="section-title">
                    <h2 style={{ fontSize: "2.5em" }}>Our Programs</h2>
                    <p style={{ fontSize: "1.2em",color:"#333333" }}>
                        Explore our diverse programs designed to elevate every aspect of a young athlete's development.
                    </p>
                </div>
                <div className="row">
                    {props.data
                        ? props.data.map((d, i) => (
                                <div key={`${d.name}-${i}`} className="col-md-4">
                                    <div className="program-desc">
                                        <h3 style={{ fontSize: "1.3em",color:"#333333" }}>{d.name}</h3>
                                        <p style={{ fontSize: "1em" }}>{d.text}</p>
                                        <h3 style={{ fontSize: "1.1em",textAlign:"left",color:"#333333" }}>Key Objectives:</h3>
                                        <ul style={{ listStyleType: "disc", marginLeft: "20px", textAlign: "left", fontSize: "1em" }}>
                                            {d.objectives.map((objective, index) => (
                                                <li key={index} style={{ marginBottom: "5px" }}>
                                                    {objective}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))
                        : "Loading..."}
                </div>
            </div>
        </div>
    );
};
