import React, { useState } from "react";
import "../modals/PoliciesModal.css";

const initialState = {
  name: "",
  email: "",
  message: "",
  interest: "",
};

export const Contact = (props) => {
  const [{ name, message, interest }, setState] = useState(initialState);
  const year = new Date().getFullYear();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState })
  
  const isDesktop = window.innerWidth > 768; // Basic check for desktop
  const whatsappNumber = props.data ? props.data.whatsappDirect : "";
  const whatsappURL = isDesktop
    ? `https://web.whatsapp.com/send?phone=${whatsappNumber}`
    : `https://wa.me/${whatsappNumber}`;;


  const handleSubmit = (e) => {
    e.preventDefault(); 

    const subject = `I am reaching out to get information about ${interest}.`;
    const body = `Hi, my name is ${name}.\n I would like to be informed about ${interest}.\n ${message}`;

    
    const mailtoLink = `mailto:info@drperformance.nl?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
    clearState();
  };

  return (
    <div>
      <div id="contact" className="py-5" style={{width:"100%"}}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <select
                    name="interest"
                    id="interest"
                    className="form-control"
                    value={interest}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Interest</option>
                    <option value="Dr Performance Camps">Dr Performance Camps</option>
                    <option value="Individual Practices">Individual Practices</option>
                    <option value="Amsterdam Basketball Academy Registration">Amsterdam Basketball Academy</option>
                  </select>
                </div>
                <div className="form-group mb-3">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
            <div className="col-md-3 offset-md-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={whatsappURL}target="_blank" rel="noopener noreferrer" >
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer"style={{width:"100%"}}>
        <div className="container text-center">
          <p>
            &copy; {year} Dr. Performance Skills & Mindset | All Rights Reserved
          </p>
          <p>
            Enhanced & Maintained by <a href="https://www.linkedin.com/in/alidenizunal/" target="_blank" rel="noopener noreferrer">Ali Deniz Ünal</a>
          </p>
          <p>
            Design & Developed by <a href="https://www.linkedin.com/in/sevim-ezgi-bulamaci/" target="_blank" rel="noopener noreferrer">Sevim Ezgi Bulamacı</a>
          </p>
        </div>
      </div>
    </div>
  );
};
