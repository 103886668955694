import React, { useState, useEffect } from "react";
import { NavigationMain } from "./navigationmain";
import { Programs } from "./programs";
import { Staff } from "./Staff";
import { Contact } from "./contact";
import JsonData from "../data/english.json";
import "../App.css"
import { AbaAbout } from "./abaAbout";
import { GalleryAba } from "./galleryAba";



export const Aba = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  const handleMargin = () => {
    if(window.innerWidth <= 768){
      return {marginTop: '100px'}
    }
    else return {marginTop: '170px'}
  }

  return (
    <div style={{ backgroundColor: "#f6f6f6"}}>
      <NavigationMain />
      <div style={handleMargin()}>{/*First element margin*/}</div>
      <AbaAbout data={landingPageData.AboutAba} />
      <Programs data={landingPageData.ProgramsAba} />
      <GalleryAba data={landingPageData.GalleryAba} />
      <Staff data={landingPageData.Staff} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};
