import React, { useState, useRef } from "react";
import { Image } from "./image";

export const GalleryPerformance = (props) => {
  const galleryRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);




  const scrollLeft = (galleryRef) => {
    if (galleryRef.current) {
      const totalItems = galleryRef.current.children.length;
      const itemWidth = galleryRef.current.firstChild.offsetWidth; // Add gap
      const visibleWidth = galleryRef.current.offsetWidth;
      const totalWidth = itemWidth * totalItems;

      if (galleryRef.current.scrollLeft <= 0) {
        // If at the start, jump to the end
        galleryRef.current.scrollLeft = totalWidth - visibleWidth;
      } else {
        // Scroll left by 1 item
        if(window.innerWidth <= 768){
          galleryRef.current.scrollBy({ left: -itemWidth * 1, behavior: 'smooth' });
        }
        else
        // Scroll left by 3 items
        galleryRef.current.scrollBy({ left: -itemWidth * 3, behavior: 'smooth' });
      }
    }
  };

  const scrollRight = (galleryRef) => {
    if (galleryRef.current) {
      const totalItems = galleryRef.current.children.length;
      const itemWidth = galleryRef.current.firstChild.offsetWidth+ 10;
      const visibleWidth = galleryRef.current.offsetWidth;
      const totalWidth = itemWidth * totalItems;

      if (galleryRef.current.scrollLeft + visibleWidth >= totalWidth-40) {
        // If at the end, jump to the start
        galleryRef.current.scrollLeft = 0;
      } else {
        // Scroll right by 1 item
        if(window.innerWidth <= 1024){
          galleryRef.current.scrollBy({ left: itemWidth * 1, behavior: 'smooth' });
        }
        else
        // Scroll right by 3 items
        galleryRef.current.scrollBy({ left: itemWidth * 3, behavior: 'smooth' });
      }
    }
  };


  const openModal = (index) => {
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div id="gallery-aba" className="text-center" style={{width: "100%"}}>
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
        </div>
        <div className="gallery-aba-controls">
          <button
            className="gallery-button left"
            onClick={() => scrollLeft(galleryRef)}
          >
            &lt;
          </button>
          <div className="gallery-items" ref={galleryRef}>
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="gallery-item"
                    onClick={() => openModal(i)} // Open modal on image click
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
          <button
            className="gallery-button right"
            onClick={() => scrollRight(galleryRef)}
          >
            &gt;
          </button>
        </div>
        {isModalOpen && (
          <div className="gallery-modal" onClick={closeModal} style={{width: "100.5%"}}>
            <div
              className="gallery-modal-content"
            >
              <button className="close-button" onClick={closeModal} style={{ position: 'absolute', top: '0', right: '0', padding: '10px', fontSize: '20px', cursor: 'pointer' }}>
                &times;
              </button>
              <div className="gallery-aba-controls">
                <button
                  className="gallery-aba-button left"
                  onClick={(e) =>{
                    e.stopPropagation();
                    setSelectedImageIndex(
                      (selectedImageIndex - 1 + props.data.length) % props.data.length
                    );                      
                  }
                  }
                >
                  &lt;
                </button>
                <div
                  className="modal-image-container"
                  style={{
                    display: "flex",
                    transition: "transform 0.5s ease-in-out", // Smooth transition
                    transform: `translateX(${-selectedImageIndex * 100}%)`, // Slide images
                    width: `${props.data.length * 100}%`, // Adjust width for all images
                  }}
                >
                  {props.data &&
                    props.data.map((image, i) => (
                      <div
                        key={`${image.title}-${i}`}
                        style={{
                          flex: "0 0 100%", // Each image takes full width
                          display: "flex",
                          justifyContent: "center", // Center the image horizontally
                          alignItems: "center", // Center the image vertically
                        }}
                      >
                        <div onClick={(e) => e.stopPropagation()}>
                          <Image
                            title={image.title}
                            largeImage={image.largeImage}
                            smallImage={image.smallImage}
                          />                          
                        </div>
                      </div>
                    ))}
                </div>
                <button
                  className="gallery-aba-button right"
                  onClick={(e) =>{
                      e.stopPropagation();
                      setSelectedImageIndex(
                        (selectedImageIndex + 1) % props.data.length
                      );                      
                    }
                  }
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
